import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/improve-javascript-performance-with-treeshaking",
  "date": "2022-02-13",
  "title": "Improve JavaScript Performance with Tree Shaking",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "Tree shaking is the process used by bundlers like Webpack for dead-code elimination in JavaScript."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p><em parentName="p">{`Tree shaking`}</em>{` is the process used by bundlers like Webpack for dead-code elimination in JavaScript. It relies on the `}<a parentName="p" {...{
        "href": "http://exploringjs.com/es6/ch_modules.html#static-module-structure"
      }}>{`static structure`}</a>{` of ES2015 or ES6 module syntax,
i.e. `}<inlineCode parentName="p">{`import`}</inlineCode>{` and `}<inlineCode parentName="p">{`export`}</inlineCode>{`.`}</p>
    <p>{`We can follow the same concepts used by these bundlers in our code to optimise our apps and increase performance.`}</p>
    <h2>{`Why do we need tree shaking?`}</h2>
    <p>{`The tree-shaking process reduces the code bundle size by shaving off the unused components and libraries included in our bundle. If this process wasn’t available, then we will be shipping all the components and libraries included in our app, even though they are not being used. This results in increased download time, longer decompressing, parsing and even executing times.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2022-02-13-improve-javascript-performance-with-treeshaking/tree-shaking.png",
        "alt": "Source: Google"
      }}></img></p>
    <p>{`This way, tree-shaking helps us to:`}</p>
    <ol>
      <li parentName="ol">{`Reduce transpiled code size`}</li>
      <li parentName="ol">{`Increased bundle download time`}</li>
      <li parentName="ol">{`Shorter bundle decompressing time`}</li>
      <li parentName="ol">{`Faster code parsing time`}</li>
      <li parentName="ol">{`And faster execution time`}</li>
    </ol>
    <p>{`The older JavaScript module import syntax like AMD modules and CommonJS, used to import code dynamically, which make it hard to analyse code size at the time it was transpiled. But with the introduction of webpack 2, built-in support for ES2015 was introduced as well as unused module export detection.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// CommonJS: The entire package is imported
const lodash = require('lodash'); 70.7K (gzipped: 24.7k);


// ES2015(ES6): A specific dependency is imported with tree shaking
import toLower from "lodash/toLower"; 2.2K (gzipped: 942);
`}</code></pre>
    <p>{`By using ES2015 moulde import syntax here, the tree-shaking technique is importing a dependency by importing what’s only required from the global package, reducing the size of the imported dependencies, instead of importing the whole global package.`}</p>
    <h2>{`Minimising sideEffects`}</h2>
    <p>{`The new webpack 4 release expands on this capability with a way to provide hints to the compiler by using the `}<inlineCode parentName="p">{`sideEffects`}</inlineCode>{` property in the `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` file to denote which files in your project are "pure" and therefore safe to prune if unused.`}</p>
    <p><strong parentName="p"><inlineCode parentName="strong">{`sideEffects`}</inlineCode></strong>{` is much more effective since it allows to skip whole modules/files and the complete subtree.`}</p>
    <p>{`Side effects happen when a function or expression modifies state outside its own context. Some examples of side effects include making a call to an API, manipulating the DOM, and writing to a database. In order to exclude such files or make webpack aware of the state of the files it’ll be transpiling, we can go ahead and configure this in either the `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` file or within the webpack.config.json file like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "name": "webpack-tree-shaking-example",
  "version": "1.0.0",
  "sideEffects": false
}
`}</code></pre>
    <p>{`Or alternatively, in webpack.config.json file to specify which files to exclude:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "name": "Webpack Tree Shaking Example",
  "side-effects": ["./src/a-file-with-side-effect.js"]
}
`}</code></pre>
    <p>{`This assumes that any file that is not included in the list, is free of side effects.`}</p>
    <p>{`You can also add these files via module.rules as below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`module.rules: [
  {
    include: path.resolve("node_modules", "lodash"),
    sideEffects: false
  }
]
`}</code></pre>
    <h2>{`Prevent Babel from transpiling ES6 modules to CommonJS modules`}</h2>
    <p>{`If you're using `}<a parentName="p" {...{
        "href": "https://babeljs.io/docs/plugins/preset-env/"
      }}>{`babel-preset-env`}</a>{` in your Babel config, it automatically transpile your ES6 modules into more widely compatible CommonJS modules (converting `}<inlineCode parentName="p">{`imports`}</inlineCode>{` into `}<inlineCode parentName="p">{`require`}</inlineCode>{`). This becomes problematic when we want to start tree shaking, as webpack won’t know what to prune from the bundle if we use it.`}</p>
    <p>{`To fix this issue, we can tell `}<a parentName="p" {...{
        "href": "https://babeljs.io/docs/plugins/preset-env/"
      }}>{`babel-preset-env`}</a>{` to ignore ES6 modules:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "presets": [
    [
      "env",
      {
        "modules": false
      }
    ]
  ]
}
`}</code></pre>
    <p>{`Now instead of Babel converting your code for wide compatibility, webpack can convert the code as well as tree-shake.`}</p>
    <h2>{`Import only modules you want`}</h2>
    <p>{`Just like in the example where we imported the toLower module from lodash, we can do the same for the moduels we write.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// import sort from "../utils/sort";

import { quickSort } from '../utils/sort';

// sort.quickSort();

quickSort();
`}</code></pre>
    <p>{`Rather than importing the whole module, we have only imported the export we needed. This will collectively with other module imports, will significantly reduce your final bundle size, resulting in faster bundle download speeds, increasing the page load speed.`}</p>
    <h2>{`Minify the output`}</h2>
    <p>{`By using the importand export syntax, we managed to mark our "dead code" to be dropped, but we still need to drop it from the bundle. In order to do that, set the modeconfiguration option to production.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`module.exports = {
  entry: './src/index.js',
  output: {
    filename: 'bundle.js',
    path: path.resolve(__dirname, 'dist'),
  },
  mode: 'production',
};
`}</code></pre>
    <h2>{`Summary`}</h2>
    <p>{`What we've learned is that in order to take advantage of `}<em parentName="p">{`tree shaking`}</em>{`, you must...`}</p>
    <ul>
      <li parentName="ul">{`Use ES2015 module syntax (i.e. `}<inlineCode parentName="li">{`import`}</inlineCode>{` and `}<inlineCode parentName="li">{`export`}</inlineCode>{`).`}</li>
      <li parentName="ul">{`Ensure no compilers transform your ES2015 module syntax into CommonJS modules (this is the default behaviour of the popular Babel preset @babel/preset-env - see the `}<a parentName="li" {...{
          "href": "https://babeljs.io/docs/en/babel-preset-env#modules"
        }}>{`documentation`}</a>{` for more details).`}</li>
      <li parentName="ul">{`Add a `}<inlineCode parentName="li">{`"sideEffects"`}</inlineCode>{` property to your project's `}<inlineCode parentName="li">{`package.json`}</inlineCode>{` file.`}</li>
      <li parentName="ul">{`Use the `}<a parentName="li" {...{
          "href": "https://webpack.js.org/configuration/mode/#mode-production"
        }}>{`production`}</a>{` `}<inlineCode parentName="li">{`mode`}</inlineCode>{` configuration option to enable `}<a parentName="li" {...{
          "href": "https://webpack.js.org/configuration/mode/#usage"
        }}>{`various optimizations`}</a>{` including minification and tree shaking.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      